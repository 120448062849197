import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from '@mui/material'

import {
  Search as SearchIcon,
  Close as CloseIcon,
  FilterAltOutlined as FilterAltOutlinedIcon,
  InfoOutlined
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'

import RoomCard from '../RoomCard/RoomCard'
import AddFiltersModal from '../AddFiltersModal/AddFiltersModal'
import RoomDetailsModal from '../RoomDetailsModal/RoomDetailsModal'
import ScheduleSelector from '../ScheduleSelector/ScheduleSelector'
import AlertDialog from '../AlertDialog/AlertDialog'

import './RoomsModal.sass'
import { Dot } from '../Svgs'
import { AFTERNOON, ALLDAY, ALL_AFTERNOON, ALL_DAY, ALL_MORNING, DEFAULT_ROOM_FILTERS, MORNING, NOT_AVAILABLE, PARTIAL_AFTERNOON, PARTIAL_MORNING } from '../../../variables'
import { defaultFunction } from '../../utilities/utilities'
import { isTodayFromJsDate, today } from '../../utilities/FormatDate'

const RoomsModal = props => {
  const {
    isLarge = false,
    openRoomsList,
    onCancelRoom = defaultFunction,
    onContinueRoom = defaultFunction,
    rooms = {},
    date = today,
    defaultEntryTime,
    defaultExitTime,
    isLoading = false,
    defaultRoom,
    workplaceDefaultEntryTime,
    workplaceDefaultExitTime,
    getRoomMaxReservationTime = defaultFunction
  } = props

  const timeAvailability = useMemo(() => {
    return ({
      morning: { start: defaultEntryTime, end: '12:00' },
      afternoon: { start: '12:01', end: defaultExitTime },
      allday: { start: defaultEntryTime, end: defaultExitTime },
    })
  }, [defaultEntryTime, defaultExitTime])

  const [activeFilter, setActiveFilter] = useState(MORNING)
  const [filteredRooms, setFilteredRooms] = useState(rooms)
  const [addFiltersModalOpen, setAddFiltersModalOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState(DEFAULT_ROOM_FILTERS)
  const [openScheduleSelector, setOpenScheduleSelector] = useState(false)
  const [isDateAvailable, setIsDateAvailable] = useState(true)
  const [roomDetailsModalOpen, setRoomDetailsModalOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState(defaultRoom)

  const { t } = useTranslation()

  const filterRooms = useCallback((rooms = []) => {
    // Filter text search
    if (search) {
      const _search = search.toLocaleLowerCase()
      rooms = rooms.filter(item =>
        item.name.toLowerCase().includes(_search)
      )
    }

    // Filter by Site, Floor, Building, Capacity and Amenities
    if (filter.filtered) {
      rooms = rooms?.filter(room => {
        const matchesSite = filter.site === -1 || room.site?.id === filter.site
        const matchesBuilding = filter.building === -1 || room.building?.id === filter.building
        const matchesFloor = filter.floor === -1 || room.floor?.id === filter.floor
        const matchesCapacity = !filter.capacity || room.capacity >= filter.capacity
        const matchesAmenities = filter.amenities?.length === 0 || filter.amenities?.every(amenity => room.amenities.includes(amenity))

        return matchesSite && matchesBuilding && matchesFloor && matchesCapacity && matchesAmenities
      })
    }

    return rooms
  }, [filter, search])

  useEffect(() => {
    setFilteredRooms(filterRooms(rooms))
    getRoomMaxReservationTime(selectedRoom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, rooms, filter])

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number)
    return hours * 60 + minutes;
  }

  const checkRoomAvailability = useCallback((availability, timeSlot) => {
    if (timeSlot === timeAvailability.allday) {
      return availability?.some(slot =>
        timeToMinutes(slot[0]) <= timeToMinutes(timeSlot.start) &&
        timeToMinutes(slot[1]) >= timeToMinutes(timeSlot.end)
      )
    }

    return availability?.some(slot =>
      timeToMinutes(slot[0]) < timeToMinutes(timeSlot.end) &&
      timeToMinutes(slot[1]) > timeToMinutes(timeSlot.start)
    )
  }, [timeAvailability])

  const handleCloseScheduleSelector = useCallback(() => {
    setOpenScheduleSelector(false)
    if (!isDateAvailable) setIsDateAvailable(true)
  }, [isDateAvailable])

  const handleBackScheduleSelector = useCallback(() => {
    setOpenScheduleSelector(false)
    if (!isDateAvailable) setIsDateAvailable(true)
  }, [isDateAvailable])

  const handleFilterRoomsByTime = useCallback(filter => {
    setSelectedRoom(defaultRoom)
    setActiveFilter(filter)
  }, [setSelectedRoom, defaultRoom])

  const onConfirmRoom = useCallback(() => {
    if (selectedRoom.label === ALL_DAY && !isTodayFromJsDate(date) && selectedRoom.max_reservation_time === 0) setOpenAlert(true)
    else setOpenScheduleSelector(true)
  }, [date, selectedRoom])

  const checkPartialAvailability = useCallback((availability, timePeriod) => {
    const periodStart = timeToMinutes(timePeriod.start);
    const periodEnd = timeToMinutes(timePeriod.end);
    const periodDuration = periodEnd - periodStart;

    return availability.some(([start, end]) => {
      const correctedStart = start < timePeriod.start ? timePeriod.start : start
      const correctedEnd = end > timePeriod.end ? timePeriod.end : end

      const slotStart = timeToMinutes(correctedStart)
      const slotEnd = timeToMinutes(correctedEnd)
      const slotDuration = slotEnd - slotStart

      const isPartiallyAvailable = slotStart < periodEnd && slotEnd > periodStart && slotDuration < periodDuration
      return isPartiallyAvailable
    })
  }, [])

  const determineStatus = useCallback(room => {
    let status = { message: t('Not Available'), className: 'not-available', label: NOT_AVAILABLE }

    const isAllDayAvailable = checkRoomAvailability(room.availability, timeAvailability.allday)
    if (isAllDayAvailable) status = { message: t('Available all day'), className: '', label: ALL_DAY }

    else if (activeFilter === MORNING) {
      const isMorningAvailable = checkRoomAvailability(room.availability, timeAvailability.morning)
      const isPartiallyMorningAvailable = checkPartialAvailability(room.availability, timeAvailability.morning)

      if (isPartiallyMorningAvailable) status = { message: t('Available some hours'), className: 'partially-available', label: PARTIAL_MORNING }
      else if (isMorningAvailable) status = { message: t('Available all morning'), className: '', label: ALL_MORNING }
    }

    else if (activeFilter === AFTERNOON) {
      const isAfternoonAvailable = checkRoomAvailability(room.availability, timeAvailability.afternoon)
      const isPartiallyAfternoonAvailable = checkPartialAvailability(room.availability, timeAvailability.afternoon)

      if (isPartiallyAfternoonAvailable) status = { message: t('Available some hours'), className: 'partially-available', label: PARTIAL_AFTERNOON }
      else if (isAfternoonAvailable) status = { message: t('Available all afternoon'), className: '', label: ALL_AFTERNOON }
    }

    return status

  }, [activeFilter, checkPartialAvailability, checkRoomAvailability, t, timeAvailability])

  const showNoRoomsAvailableMsg = useCallback(() => {
    if (!isLoading) {
      return (
        <Box className='no-rooms-available'>
          <InfoOutlined />
          <h2>{t("Sorry, there is no data available according to the filters applied. Select other parameters to query.")}</h2>
        </Box>
      )
    }
  }, [isLoading, t])

  const handleApplyFilters = params => {
    setFilter(params)
    setSelectedRoom(defaultRoom)
    setAddFiltersModalOpen(false)
  }

  const onContinueScheduleSelector = useCallback(params => {
    setOpenScheduleSelector(false)
    onContinueRoom({ ...params, selectedRoom })
  }, [onContinueRoom, selectedRoom])

  const handleCloseRoomDetails = () => {
    setRoomDetailsModalOpen(false)
  }

  const handleOpenRoomDetails = () => {
    setRoomDetailsModalOpen(true)
  }

  const handleSeeWhoBooked = () => {
    setOpenScheduleSelector(true)
    setIsDateAvailable(false)
  }

  const handleSelectCustomTime = useCallback(() => {
    setOpenAlert(false)
    setRoomDetailsModalOpen(false)
    setOpenScheduleSelector(true)
  }, [])

  const handleSelectAllTime = useCallback(() => {
    setOpenAlert(false)
    setRoomDetailsModalOpen(false)
    onContinueRoom({ allTime: true, selectedRoom })
  }, [onContinueRoom, selectedRoom])

  const enabledToContinue = useCallback(() => {
    return (
      selectedRoom.id !== -1 &&
      selectedRoom.label !== NOT_AVAILABLE
    )
  }, [selectedRoom])

  return (
    <>
      <Dialog
        className="dialog-detail room-list-modal"
        fullScreen={!isLarge}
        fullWidth={true}
        maxWidth="lg"
        open={openRoomsList}
        onClose={onCancelRoom}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-detail-content">
          <Box className='rooms-modal-header'>
            <h2>{t('Select the room to book')}</h2>
            <IconButton
              onClick={onCancelRoom}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className='rooms-modal-searchbar-container'>
            <TextField
              id="input-with-icon-textfield"
              placeholder={t('Search')}
              fullWidth
              value={search}
              onChange={event => setSearch(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className='search-input-adornment'
                  >
                    <SearchIcon fontSize='small' />
                  </InputAdornment>
                ),
              }}
              variant="filled"
            />
            <Box className='add-filter-button-container'>
              {filter.filtered && <Dot width={45} style={{ fill: '#34C700' }} />}
              <Button
                onClick={() => setAddFiltersModalOpen(true)}
                variant="text"
                startIcon={<FilterAltOutlinedIcon />}>
                {t('Add filter')}
              </Button>
            </Box>
          </Box>
          <Box className='rooms-modal-room-avalability-filter-container'>
            <span>{t('Room available at')}</span>
            <Box className='availability-filters'>
              <Button
                variant={activeFilter === MORNING ? 'contained' : 'outlined'}
                className='availability-filter-button'
                onClick={() => handleFilterRoomsByTime(MORNING)}
              >
                {t('The Morning')}
              </Button>
              <Button
                variant={activeFilter === AFTERNOON ? 'contained' : 'outlined'}
                className='availability-filter-button'
                onClick={() => handleFilterRoomsByTime(AFTERNOON)}
              >
                {t('The Afternoon')}
              </Button>
              <Button
                variant={activeFilter === ALLDAY ? 'contained' : 'outlined'}
                className='availability-filter-button'
                onClick={() => handleFilterRoomsByTime(ALLDAY)}
              >
                {t('All day')}
              </Button>
            </Box>
          </Box>
          <Box className='room-list' >
            {filteredRooms.length === 0 ?
              showNoRoomsAvailableMsg() :
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={3}
              >
                {filteredRooms.map(room => {
                  const status = determineStatus(room)
                  //if (status.label === NOT_AVAILABLE && !search) return ''
                  return (
                    <Grid item key={room.id}>
                      <RoomCard
                        status={status}
                        handleOpenRoomDetails={handleOpenRoomDetails}
                        handleSeeWhoBooked={handleSeeWhoBooked}
                        setSelectedRoom={setSelectedRoom}
                        selectedRoom={selectedRoom}
                        room={room}
                      />
                    </Grid>
                  )
                }
                )}
              </Grid>
            }
          </Box>
          <div className='new-reservation-footer'>
            <Button
              className='rooms-modal-continue-button'
              fullWidth={!isLarge}
              variant="contained"
              onClick={onConfirmRoom}
              disabled={!enabledToContinue()}
            >
              {t('continue')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <AddFiltersModal
        handleClose={() => setAddFiltersModalOpen(false)}
        handleApplyFilters={handleApplyFilters}
        open={addFiltersModalOpen}
        rooms={rooms}
      />
      <RoomDetailsModal
        setRoomDetailsModalOpen={setRoomDetailsModalOpen}
        handleClose={handleCloseRoomDetails}
        onConfirmRoom={onConfirmRoom}
        open={roomDetailsModalOpen}
        room={selectedRoom}
      />
      <AlertDialog
        onClickLeftButton={handleSelectCustomTime}
        onClickRightButton={handleSelectAllTime}
        handleClose={() => setOpenAlert(false)}
        open={openAlert}
      />
      <ScheduleSelector
        handleClose={handleCloseScheduleSelector}
        onContinue={onContinueScheduleSelector}
        handleBack={handleBackScheduleSelector}
        isToday={isTodayFromJsDate(date)}
        prevReservations={selectedRoom?.usage}
        isDateAvailable={isDateAvailable}
        open={openScheduleSelector}
        workStartTime={workplaceDefaultEntryTime}
        workEndTime={workplaceDefaultExitTime}
        maxReservationTime={selectedRoom?.max_reservation_time}
      />
    </>
  )
}


export default RoomsModal